import React from 'react'
import styles from '../main/MainLayout.module.css'
import {FaLinkedin,FaTwitter,FaGithub,FaInstagram,FaBlogger,FaGooglePlay} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import {API_URL, IMAGE_URL} from '../../client'
import axios from 'axios'
import { useState } from 'react';

const MainLayout = () => {
  const [coverData,setCoverData]=useState({});
    useEffect(()=>{
      axios.get(`${API_URL}/coverpage`)
      .then(response=>setCoverData(response.data[0]))
    },[])
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.leftBox}>
        <div className={styles.imageBox}>
          {coverData.image_url!=undefined ? <img src={`${IMAGE_URL+coverData.image_url}`} alt="" /> : null}
        </div>
      </div>
      <div className={styles.rightBox}>
        <div className={styles.textContainers}>
          <p className={styles.nameText}>{coverData.cover_name}</p>
          <p className={styles.jobText}>{coverData.cover_job}</p>
          <p className={styles.aboutText}>{coverData.cover_text}</p>
        </div>
        <div className={styles.iconContainers}>
          <a href="https://www.linkedin.com/in/mirackeskin/"><FaLinkedin className={styles.icon}></FaLinkedin></a>
          <a href='https://github.com/mirackeskin'><FaGithub className={styles.icon}></FaGithub></a>
          <a href='https://twitter.com/mirakeskin'><FaTwitter className={styles.icon}></FaTwitter></a>
          <a href='https://www.instagram.com/mirac_keskinn/'><FaInstagram className={styles.icon}></FaInstagram></a>
          <a href='https://play.google.com/store/apps/developer?id=Mirac+Keskin'><FaGooglePlay className={styles.icon}></FaGooglePlay></a>
          <Link to={"/blog"}><FaBlogger className={styles.icon}></FaBlogger></Link>          
        </div>
      </div>
    </div>
  )
}

export default MainLayout

