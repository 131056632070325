import React from 'react'

const DashboardHome = () => {
  return (
    <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
      <h1>WELCOME HOME!!</h1>
    </div>
  )
}

export default DashboardHome
